<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("newCustomer.addNewCustomers") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start m-2 custom-input">
            <div class="row">
              <div class="col-6 py-2 my-4">
                <label class="cstm-check dropdown-item">
                  <span class="text-black mx-2 regular-14">{{
                    $t("newCustomer.person")
                  }}</span>
                  <input
                    type="radio"
                    :value="0"
                    v-model="newMember.is_business"
                  /><span class="checkmark mx-2"></span>
                </label>
              </div>
              <div class="col-6 py-2 my-4">
                <label class="cstm-check dropdown-item">
                  <span class="text-black mx-2 regular-14">{{
                    $t("newCustomer.company2")
                  }}</span>
                  <input
                    type="radio"
                    :value="1"
                    v-model="newMember.is_business"
                  /><span class="checkmark mx-2"></span>
                </label>
              </div>
            </div>
            <div class="row">
              <div
                class="col-6 mb-3 whitebg-input"
                v-if="newMember.is_business === 0"
              >
                <label class="form-label bold-12 text-gray">
                  {{ $t("finance.firstName") }}
                </label>
                <input class="form-control" v-model="newMember.first_name" />
              </div>
              <div
                class="col-6 mb-3 whitebg-input"
                v-if="newMember.is_business === 0"
              >
                <label class="form-label bold-12 text-gray">
                  {{ $t("newCustomer.lastName2") }}
                </label>
                <input class="form-control" v-model="newMember.last_name" />
              </div>
              <div
                class="col-12 mb-3 whitebg-input"
                v-if="newMember.is_business === 1"
              >
                <label class="form-label bold-12 text-gray">
                  {{ $t("addNewContactsModal.name2") }}
                </label>
                <input class="form-control" v-model="newMember.name" />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ $t("finance.email") }}
                </label>
                <input class="form-control" v-model="newMember.email" />
              </div>
            </div>
            <div class="mb-3 whitebg-input">
              <label class="form-label bold-12 text-gray">{{
                $t("address.country")
              }}</label>
              <v-select
                :options="countries"
                v-model="newMember.country_id"
                label="name"
                :placeholder="$t(`address.selectCountry`)"
                :reduce="(c) => c.id"
                append-to-body
              />
            </div>
            <div class="row mb-3 custom-inpu">
              <div class="col-4 whitebg-input">
                <label class="form-label bold-12 text-gray">{{
                  $t("companies.postCode")
                }}</label>
                <input
                  type="text"
                  v-model="newMember.post_code"
                  class="form-control"
                />
              </div>
              <div class="col whitebg-input">
                <label class="form-label bold-12 text-gray">{{
                  $t("address.city")
                }}</label>
                <input
                  type="text"
                  v-model="newMember.city"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row mb-3 whitebg-input">
              <div class="col">
                <label class="form-label bold-12 text-gray">{{
                  $t("address.street")
                }}</label>
                <input
                  type="text"
                  v-model="newMember.street"
                  class="form-control"
                />
              </div>
              <div class="col-5 whitebg-input">
                <label class="form-label bold-12 text-gray">{{
                  $t("address.house")
                }}</label>
                <input
                  type="text"
                  v-model="newMember.house"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="addCompany"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("finance.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
const bootstrap = require("bootstrap");

export default {
  name: "NewCustomer",
  props: {
    modelValue: Object,
    show: Boolean,
  },
  emits: ["update:modelValue", "update:show", "add"],
  data() {
    return {
      myModal: null,
      success: false,
      loadAddBtn: false,
      countries: [],
      newMember: {
        is_business: 0,
      },
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.myModal.show();
      this.showAddModal();
    } else {
      this.myModal.hide();
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.myModal.show();
        this.showAddModal();
      } else {
        this.myModal.hide();
      }
    },
  },
  methods: {
    addCompany() {
      this.loadAddBtn = true;
      http
        .fetch("/crm/customers", this.newMember, true)
        .then((data) => {
          this.loadAddBtn = false;
          this.myModal.hide();
          this.$emit("add", data);
          this.$emit("update:show", false);
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    showAddModal() {
      if (this.countries.length === 0) {
        http.fetch("/countries/all").then((data) => {
          this.countries = data;
        });
      }
    },
  },
  components: {},
};
</script>
